import { get, post } from "@/utils/axios";

function $_buildPersonFiltersObj(
  personFilters // { nodeNames, alertDispGuids, personInfoList }
) {
  let testPerson = {};
  let filtersObj = null;
  if (personFilters) {
    let personInfoList = personFilters.personInfoList || [];
    for (let peronInfo of personInfoList) {
      testPerson[peronInfo.field] = peronInfo.value;
    }
    filtersObj = {
      nodeNames: personFilters.nodeNames || [],
      alertDispGuids: personFilters.alertDispGuids || [],
      testPerson
    };
  }
  return filtersObj;
}

function $_buildPersonPagingObj(
  personPaging //{ offset, limit }
) {
  let pagingObj = null;
  if (personPaging) {
    pagingObj = {
      offset: personPaging.offset || 0,
      limit: personPaging.limit || 10
    };
  }
  return pagingObj;
}

export function fetchTestPersonList(
  regionGuid,
  nodeGuids,
  personFilters, // { nodeNames, alertDispGuids, personInfoList }
  personPaging, //{ offset, limit }
  orderList // [ { fieldName, orderDirection } ]
) {
  return post("/testperson/TestPersonList", {
    customerGuid: regionGuid,
    nodeGuidList: nodeGuids,
    filters: $_buildPersonFiltersObj(personFilters),
    paging: $_buildPersonPagingObj(personPaging),
    orderBy: orderList
  });
}

export async function fetchTestPersonGuidList(
  regionGuid,
  nodeGuids,
  personFilters // { nodeNames, alertDispGuids, personInfoList }
) {
  let response = await post("/testperson/SelectTestPersonGuidList", {
    customerGuid: regionGuid,
    nodeGuidList: nodeGuids,
    filters: $_buildPersonFiltersObj(personFilters)
  });
  return response.guidList;
}

export function fetchTestPersonDetails(personGuid) {
  return get("/testperson/TestPersonDetails", {
    testPersonGuid: personGuid
  });
}

export function fetchTestPersonProfileItems(personGuid, nodeGuid) {
  return post("/testperson/TestPersonProfileItems", {
    guid: personGuid,
    nodeGuid
  });
}

export async function createNewTestPerson(regionGuid, personInfo, password) {
  let response = await post("/testperson/CreateNewTestPerson", {
    customerGuid: regionGuid,
    ...personInfo,
    age: personInfo.age && Number(personInfo.age),
    password
  });
  return response.guid;
}

export async function saveByCreatingNewEditedPerson(
  regionGuid,
  personInfo,
  noUpdateFieldNames
) {
  let response = await post("/testperson/SaveByCreatingNewEditedPerson", {
    customerGuid: regionGuid,
    ...personInfo,
    age: personInfo.age && Number(personInfo.age),
    noUpdateFieldNames: noUpdateFieldNames
  });
  return response.guid;
}

export async function savePersonBasicInfo(personInfo, noUpdateFieldNames) {
  let response = await post("/testperson/SavePersonBasicInfo", {
    ...personInfo,
    age: personInfo.age && Number(personInfo.age),
    noUpdateFieldNames: noUpdateFieldNames
  });
  return response.guid;
}

export async function bulkUpdatePersonPassword(
  personGuidList,
  useEmptyPassword,
  newPassword
) {
  let response = await post("/testperson/BulkUpdatePersonPassword", {
    testPersonGuidList: personGuidList,
    useEmptyPassword,
    newPassword
  });
  return response.value;
}

export async function bulkDeletePerson(personGuidList) {
  let response = await post("/testperson/DeletePersonList", personGuidList);
  return response.guidList;
}

export async function downloadPersonListExcel({
  sheetTitle,
  regionGuid,
  nodeGuid,
  personGuidList
}) {
  let response = await post("/testpersonexport/ExportPersonListToExcel", {
    sheetTitle,
    customerGuid: regionGuid,
    nodeGuid,
    personGuidList
  });
  return response.value;
}

export async function downloadPersonListTemplate({
  regionGuid,
  nodeGuid,
  nodeName,
  identityType,
  fieldNameList
}) {
  let response = await post("/testpersonimport/GeneratePersonSheetTemplate", {
    customerGuid: regionGuid,
    nodeGuid,
    nodeName,
    identityType,
    fieldNameList
  });
  return response.value;
}

export async function extractInfoDictFromFile(personFile) {
  let formData = new FormData();
  formData.append("personFile", personFile, personFile.name);
  let postUrl = "/testpersonimport/ExtractInfoDictFromSheet";
  let response = await post(encodeURI(postUrl), formData);
  return response;
}

export function importPersonFromDictList(
  nodeGuid,
  personDictList,
  initPassword
) {
  return post(`/testpersonimport/ImportPersonFromDictList/${nodeGuid}`, {
    personDictList,
    initPassword: initPassword || null
  });
}
